<template>
  <div class="container is-max-desktop px-4" style="height: 100%">
    <div class="columns is-vcentered is-centered is-mobile" style="height: 100%">
      <div class="column is-5">
        <b-message type="is-success">
          The payment was made successfully
        </b-message>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {}
  },
  computed: {},
  methods: {},
  async mounted() {}
}
</script>
